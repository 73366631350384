.btn {
  @apply px-7 py-2.5 animation rounded-md hover:bg-opacity-75;
}

.animation {
  @apply transition ease-linear duration-100;
}

.card {
  @apply p-8 sm:p-16 rounded-3xl shadow-2xl;
}

.card-sm {
  @apply p-4 sm:p-16 rounded-3xl shadow-lg;
}
